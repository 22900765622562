// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from "@remix-run/react";
import primeoriginaltheme from "primereact/resources/themes/lara-light-purple/theme.css";
import primecore from "primereact/resources/primereact.min.css"; //core css
import primeicons from "primeicons/primeicons.css"; //icons
import primeflex from "primeflex/primeflex.min.css"; //primeflex
import gridSystem from "~/styles/bootstrap-grid.css";
import i18next from "~/i18next.server";
import { json } from "@remix-run/node";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next";
import styles from "~/styles/global.css";
import colors from "~/styles/colors.css";
import globalUtils from "~/styles/global-utils.css";
import animations from "~/styles/animations.css";
import aggrid from "~/styles/aggrid.css";
import primereact from "~/styles/primereact.css";
import syncfusionCustomCss from "~/styles/syncfusion-custom.css";

// Sync fusion dependencies
import { registerLicense, loadCldr, L10n } from "@syncfusion/ej2-base";
import syncfusionBaseMaterial from "@syncfusion/ej2-base/styles/material3.css";
import syncfusionButtonsMaterial from "@syncfusion/ej2-buttons/styles/material3.css";
import syncfusionScheduleMaterial from "@syncfusion/ej2-react-schedule/styles/material3.css";
import syncfusionCalendarsMaterial from "@syncfusion/ej2-calendars/styles/material3.css";
import syncfusionDropdownsMaterial from "@syncfusion/ej2-dropdowns/styles/material3.css";
import syncfusionInputsMaterial from "@syncfusion/ej2-inputs/styles/material3.css";
import syncfusionListsMaterial from "@syncfusion/ej2-lists/styles/material3.css";
import syncfusionNavigationsMaterial from "@syncfusion/ej2-navigations/styles/material3.css";
import syncfusionPopupsMaterial from "@syncfusion/ej2-popups/styles/material3.css";
import syncfusionSplitbuttonsMaterial from "@syncfusion/ej2-splitbuttons/styles/material3.css";
import reactCrop from "react-image-crop/dist/ReactCrop.css";
import { withSentry } from "@sentry/remix";
import { LicenseManager } from "ag-grid-enterprise";
import { locale as primeLocale, addLocale as primeAddLocale } from "primereact/api";
import fs from "fs";
import path from "path";
import synfusionLanguageFR from "public/locales/fr/syncfusion.json";
import gregorianFR from "~/assets/cldr-data/main/fr-CH/ca-gregorian.json";
import numbersFR from "~/assets/cldr-data/main/fr-CH/numbers.json";
import timeZoneNamesFR from "~/assets/cldr-data/main/fr-CH/timeZoneNames.json";
import numberingSystems from "~/assets/cldr-data/supplemental/numberingSystems.json";
import AgGridStyles from "ag-grid-community/styles/ag-grid.css";
import AgThemeAlpineStyles from "ag-grid-community/styles/ag-theme-alpine.css";
import useBackgroundSelector from "./components/general/accueil/backgroundSelector/useBackgroundSelector";
export function links() {
  return [{
    rel: "stylesheet",
    href: primeflex
  }, {
    rel: "stylesheet",
    href: primeoriginaltheme
  }, {
    rel: "stylesheet",
    href: primecore
  }, {
    rel: "stylesheet",
    href: primeicons
  }, {
    rel: "stylesheet",
    href: styles
  }, {
    rel: "stylesheet",
    href: colors
  }, {
    rel: "stylesheet",
    href: globalUtils
  }, {
    rel: "stylesheet",
    href: animations
  }, {
    rel: "stylesheet",
    href: AgGridStyles
  }, {
    rel: "stylesheet",
    href: AgThemeAlpineStyles
  }, {
    rel: "stylesheet",
    href: aggrid
  }, {
    rel: "stylesheet",
    href: gridSystem
  }, {
    rel: "stylesheet",
    href: primereact
  }, {
    rel: "stylesheet",
    href: reactCrop
  }, {
    rel: "stylesheet",
    href: syncfusionBaseMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionScheduleMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionButtonsMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionCalendarsMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionDropdownsMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionInputsMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionListsMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionNavigationsMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionPopupsMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionSplitbuttonsMaterial
  }, {
    rel: "stylesheet",
    href: syncfusionCustomCss
  }];
}
export async function loader({
  request
}) {
  let locale = await i18next.getLocale(request);
  const ENV = {
    AGGRID_LICENSE: process.env.AGGRID_LICENSE,
    SYNCFUSION_LICENSE: process.env.SYNCFUSION_LICENSE,
    REMIX_SENTRY_DSN: process.env.REMIX_SENTRY_DSN,
    DEV_ENABLE_MORE_ELEMENTS_PER_PAGE_OPTIONS: process.env.DEV_ENABLE_MORE_ELEMENTS_PER_PAGE_OPTIONS
  };
  let primeLocaleData = null;
  try {
    const data = fs.readFileSync(path.join(process.cwd(), "public", "locales", "fr", "common.json"), "utf-8");
    primeLocaleData = JSON.parse(data);
  } catch (err) {
    console.error("Error reading the file:", err);
  }
  return json({
    locale,
    ENV,
    primeLocaleData
  });
}
export const shouldRevalidate = () => false;
export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "common"
};
export function ErrorBoundary() {
  _s();
  const error = useRouteError();
  const {
    t
  } = useTranslation();
  return <html lang={"fr"}>
      <head>
        <title>{t("erreur")}</title>
        <Meta />
        <Links />
      </head>
      <body>
        {error?.stack ? <>
            <h1>{error?.message}</h1>
            <pre>
              <code>{error?.stack}</code>
            </pre>
          </> : <h2>{t("message_erreur_remix_global")}</h2>}

        <Scripts />
      </body>
    </html>;
}
_s(ErrorBoundary, "qX4Jxq0KCv0lyI6u4CZ0JwCo0sY=", false, function () {
  return [useRouteError, useTranslation];
});
_c = ErrorBoundary;
function App() {
  _s2();
  // TODO: move this back down to the dashboard après BATIMAT
  // I put it here to keep the state live
  const backgroundSelector = useBackgroundSelector();

  // Get the locale from the loader
  let {
    locale,
    primeLocaleData
  } = useLoaderData();
  let {
    i18n
  } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);
  const data = useLoaderData();

  // primeReact locale
  if (primeLocaleData) {
    primeAddLocale("fr", primeLocaleData);
    primeLocale("fr");
  }

  // aggrid license
  if (data.ENV.AGGRID_LICENSE) {
    LicenseManager.setLicenseKey(data.ENV.AGGRID_LICENSE);
  }
  if (data.ENV.SYNCFUSION_LICENSE) {
    registerLicense(data.ENV.SYNCFUSION_LICENSE);
    // syncfusion language load
    loadCldr(gregorianFR, numbersFR, timeZoneNamesFR, numberingSystems);
    L10n.load(synfusionLanguageFR);
  }
  return <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet context={{
        backgroundSelector
      }} />
        <ScrollRestoration />
        <script dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(data.ENV)}`
      }} />

        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s2(App, "Ws3fmKCap6PDJqY0Zbrx9YraegI=", false, function () {
  return [useBackgroundSelector, useLoaderData, useTranslation, useChangeLanguage, useLoaderData];
});
_c2 = App;
export default _c3 = withSentry(App);
var _c, _c2, _c3;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "App");
$RefreshReg$(_c3, "%default%");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;